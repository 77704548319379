<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <!-- Filters -->
      <sale-region-list-filters
        v-if="$can('audit', 'all')"
        :branch-filter.sync="branchFilter"
        :branch-options="branchOptions"
      />

      <!-- Table Container Card -->
      <b-card
        :title="$t('Sale Regions')"
        class="mb-0"
      >

        <div class="mb-2 mt-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start pl-0 mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')"
                  @keyup.enter="search"
                />
                <b-button
                  v-if="$can('create', 'sale_region')"
                  variant="primary"
                  @click="add"
                >
                  <span class="text-nowrap">{{ $t('New') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refSaleRegionListTable"
          class="position-relative"
          :items="fetchSaleRegions"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          stacked="sm"
          no-sort-reset
        >

          <!-- Column: Branch -->
          <template #cell(branch)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.branch !== null ? data.item.branch.name : null }}</span>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link
              v-if="$can('update', 'sale_region')"
              :to="{ name: 'sale-region-edit', params: { id: data.item.id } }"
              class="btn btn-outline-secondary btn-icon mr-1"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-link>
            <b-link
              v-if="$can('delete', 'sale_region')"
              class="btn btn-outline-secondary btn-icon"
              @click="del(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalSaleRegions"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

    </div>

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable,
  BLink, BPagination,
} from 'bootstrap-vue'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import SaleRegionListFilters from './SaleRegionListFilters.vue'
import storeModule from '../../../common/storeModule'
import useSaleRegionList from './useSaleRegionList'
import saleRegionStoreModule from '../saleRegionStoreModule'

export default {
  components: {
    SaleRegionListFilters,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,

    vSelect,
  },
  data() {
    return {
      branchOptions: [],
      tableColumns: [
        { key: 'name', label: this.$t('Name'), sortable: true },
        { key: 'branch', label: this.$t('Branch'), sortable: true },
        { key: 'actions', label: this.$t('Actions') },
      ],
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'sale-region'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleRegionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchSaleRegions,
      perPage,
      currentPage,
      totalSaleRegions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSaleRegionListTable,
      refetchData,

      // Filters
      branchFilter,

    } = useSaleRegionList()

    return {

      fetchSaleRegions,
      perPage,
      currentPage,
      totalSaleRegions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSaleRegionListTable,
      refetchData,

      // Extra Filters
      branchFilter,

    }
  },
  mounted() {
    this.loadBranches()
  },
  methods: {
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.refetchData()
    },
    add() {
      // add sale-region codes
      this.$router.push({ name: 'sale-region-new' })
    },
    del(id) {
      const that = this
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('sale-region/del', id)
            .then(response => {
              if (response.status === 200) {
                that.refetchData()
              } else {
                console.log(response)
              }
            })
            .catch(error => {
              window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
